.card_container{
    flex-wrap: wrap;
    gap: 2rem;
}

@media screen and (max-width: 992px) {
    .card_container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.mainAdd{
    flex-direction: column;
}