

.admin_search_bar{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}


/* Cards */


.main_admin_cards{
    width: 85rem;
    /* border: solid green; */
}
.admin_delivery_cards{
    margin-top: 5px;
    display: flex;
    gap:1rem;
    width: 100%;
    flex-wrap: wrap;

}
.delievery_cards_head {
    font-size: 20px;
    display: flex;
    justify-content: space-around;
    font-weight: 700;
    color: navy;
    margin-top: 5rem;
}

.pending_cards_container{
    background-color: grey;
    /* font-size: 10px; */
   
}
.pending_card,.dispatched_card,.delievered_card{
    width: fit-content;
}

.order_id_info{
    display: flex;
    width: 6rem;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}
.order_id p{
  font-weight: 700;
  font-size: 13px;
}
#real_id{
    color: navy;
    margin-top: 0;
    font-weight: 700;
    font-size: 18px;
}

.pending_card_content{
    /* background-color: white; */
    /* border: solid 2px; */
    border-radius: 10px;
    margin-top: 20px;
    padding: 1rem;
}
.vertical-line {
    border-left: 2px solid black; /* Adjust thickness and color as needed */
    height: 10rem; /* Adjust height as needed */
}

.pend_card_1{
    border-radius: 10px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.332);
    background-color: white;
    display: flex;
    gap:5px;
    width: 24rem;
    margin-top:2rem;
}
.pend_card_profiles{
    display: flex;
    justify-content: space-between;
    /* border:solid 2px red; */
    width: 16rem;   
}

.pend_card_1{
    padding: 5px;
}
.pend_main_content h5{
    font-size: 18px;
    font-weight: 700;
    color: orange;
}
.pend_main_content {
    margin-left: 1rem;
}
.card_address span{
    font-size:12px;
    font-weight: 500;
}
.card_address p{
    font-size:12px;
}
.user_card_img img{
width: 3rem;
border-radius: 50%;
}

.user_card p{
    font-size: 8px;
}

.pending_cards_container {
    overflow-y: auto; /* Add vertical scrollbar if content overflows */
    max-height: 500px; /* Set a maximum height for the container */
    overflow-x: hidden;
    border-radius: 10px;
}


@media (max-width:760px) {
    .admin_delivery_cards{
/* flex-wrap: wrap; */

    }
    .main_admin_cards{
        width: 20rem;
        justify-content: center;
        margin-left: 10px;
    }
}