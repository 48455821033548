.login_svg{
    width: 35rem;
    /* border: solid 2px; */
}
.top_content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.top_content img{
    width: 5rem;

}

.login_container{
    display: flex;
    margin-top: 10px;
    /* border: solid 2px; */
    /* justify-content: center; */
    align-items: center;
    gap:10rem;
}

.right_head h2{
    font-size: 3rem;
    font-weight: 700;

}
.right_head p{
    font-size: 1rem;
    font-weight: 600;
}
.right_content{
    margin: 0px 15px;
}

.login_btn button{
    background-color: #FF9F00;
    color: white;
}


@media screen and (max-width: 992px) {

    .left_content img{
        display: none;
    }
    .backkground_login{ 
        background-image: url('../Images/blob1.svg');  
        background-repeat: no-repeat; /* Prevent repeating the image */
        background-position: right top; /* Position the image at the left top corner */
        background-attachment: fixed; /* Fix the background image */
        min-height: 100vh;
        background-size: cover;
    }
    
}