.orders_sub_info{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap:30px;
}


.orders_table{
    margin-top: 5rem;
}
.orders_table table tr td{
    font-size: 14px ;
}

#address_td{
    width: 18rem;
}

.photo_btn_td{
    width: 7rem;
    gap:3rem;

}
.photo_2_td{
    width: 7rem;
}

#order_id_td,#customer_id_td{
    width: 8rem;
}
#status_td select{
    font-size: 13px;
    color: white;
}
#status_td{
    width: 10rem;
}

.orders_table table thead tr th {
    font-size: 14px;
    color: gray;

}


@media (max-width:760px) {

    .orders_sub_info{
        flex-wrap: wrap;
    }


}