.login_svg{
    width: 30vw !important;
    /* border: solid 2px; */
}
.top_content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.top_content img{
    width: 5rem;

}

.login_container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:5rem;
    justify-self: center;
}

.right_head h2{
    font-size: 3rem;
    font-weight: 700;

}
.right_head p{
    font-size: 1rem;
    font-weight: 600;
}
.right_content{
    margin: 0px 15px;
}

.login_btn button{
    background-color: #FF9F00;
    color: white;
}

#second_address:hover{
    cursor: pointer;
    color: #FF9F00;
}


@media (max-width:992px) {
    .right_head{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
    }
    .login_container{
        flex-wrap: wrap;
        display: block !important;   
        margin-top: 1rem !important;
 }
 .image_page_not{
    width: 100vw !important;
 }
    
}

input[type = "text"], input[type = "number"], input[type = "email"],select{
    border: 1px solid !important;
    box-shadow: 2px 0px 2px 2px rgb(239, 214, 174) !important;
}