.nav-pills .nav-link.active {
    background-color: #434141;
    /* Change this to your desired color */
    color: #fff;
    /* Change text color if needed */
}

.nav-item {

    font-weight: 500;
}

.navbar-toggler-icon .icon-bar{
    background-color: black;
}