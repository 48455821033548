body{
    font-family: 'Manrope', 'sans-serif';

}
.profile{
    display: flex;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
}
.profile_img img{
   width: 10rem;
   border-radius: 50%;
}
.profile_info h2{
    font-weight: 700;
    color: navy;
}
.profile_info p{
    font-size: 20px;
}

/* Account Information */

.account_info{
    width: 70rem;
    /* border: solid ; */
    margin-top: 30px;
    
}
.account_info_head h5{
    font-weight: 700;
    color: navy;
}


.account_sub_info{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
.first{
    display: flex;
    gap:5px;
}
.first span{
    font-weight: 700;
    color: navy;
}
.first p{
    font-weight: 600;
}


/* Address Cards */

.address_cards{
    width: 70rem;
}

.address_head h5{
font-weight: 700;
color: navy;
}
.address_options{
    margin-top: 20px;
    display: flex;
    gap:30px;
}
.address_1{
    /* display:flex;
    align-items: center;
    justify-content: center; */
    width: 22rem;
    border: solid 2px;
    border-radius: 10px;
    padding: 10px;
}
.address_1 form-check label{
    font-size: 10px;
}

.form-check-input:checked[type="radio"] {
    background-color: orange; /* Change this color to your desired color */
    border-color: orange; /* Change border color if needed */
}
.form-check{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:1rem;
}


/* Subscritption Cards */
.subscrips{
    margin-top: 30px;
}
.sub_cards{
    margin-top: 30px;
    display: flex;
    gap:5rem;
    /* border:solid; */
}

#due_date{
    color: green;
}
.card_head{
    font-size: 20px;
    font-weight: 700;
}

.card_1{
    border: solid 2px black;
    padding: 7px;
    border-radius: 10px;
    width: 22rem;
}
.date_footer{
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 992px) {
    .account_info{
        width: unset;
    }
    .account_sub_info{
        display: block;
    }
    .address_cards{
        width: unset;
    }
    .address_1{
        width: unset;        
    }
    .address_options{
        display: flex;
        flex-direction: column;
    }
    .sub_cards{
        flex-direction: column;
    }
    .card_1{
        width: unset;
    }
}

@media screen and (max-width: 780px) {
    .subcard_container{
        justify-content: center !important;
    }
}

.sub_img{
    height: 150px;
    overflow: hidden;
}
.hmm{
    height: 100%;
    width: 100%;
    object-fit: cover; 
    display: block; /* Ensure the image behaves as a block element */
    margin: 0 auto;
}

.titles{
    font-weight: 700;
    color: navy;
}