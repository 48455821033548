.faq-container {
    width: 100%;
}

.faq-item {
    margin-bottom: 10px;
}

.question {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 10px;
}

.answer {
    margin-left: 30px; /* Adjust indentation as needed */
}

.email span{
    font-size: 24px;
}

.office_timing{
    margin-top: 70px;
}

.contacts{
    flex-wrap: wrap;
}
.email{
    display: flex;
    justify-content: center;
}