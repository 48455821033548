.slick-slide > div {
  margin: 0 10px;
}

.slick-dots li.slick-active button:before {
  color:orange !important;
}

.slick-list{
  border: none;
}

.review_card{
  border-radius: 7px;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.277);

}
.real_card{
  display: flex;
  flex-wrap: wrap;
  width: 500px;
}


.review_card{
  margin: 10px 0px;
  width: 200px;
  /* border: solid 4px purple; */
}