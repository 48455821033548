.footer footer{
    background-color:#FF9F00;
}
.footer{
    margin-top: 80px;
    width: 100%;
    /* width: 99%; */
    align-items: center;
    display: flex;
    justify-content: center;
}

#footer_sub li a{
    font-weight: 700;
    color: black;
}

#play{
    width: 150px;
}
@media screen and (max-width:991px) {
    .row{
        padding: 10px;
    }
}