
.admins_table{
    margin-top: 3rem;
}
.admins_table table thead tr th {
    font-size: 14px;
    color: gray;

}


.admin_control_btns{
    margin-top: 3rem;
    display: flex;
    gap:2rem;
    justify-content: center;
}
.admin_control_btns button{
    background-color: navy;
}