body {
  font-family: 'Manrope', 'sans-serif';
}

.main_hero {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


#sw-js-blob-svg {
  width: 500px;
  height: 500px;
  /* Adjust the height as needed */
  fill: url(#sw-gradient);
  /* Use the gradient fill */
}

#home {
  color: rgb(255, 148, 47);
}

.why_us_head h2 {
  font-weight: 700;
  font-size: '40px';

}

.card-body h5 {
  font-weight: 700;
  font-size: 24px;
}

.content h1 {
  font-weight: 700;
  font-size: 41px;
}

.why_us_head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

}

.why_us_cards {
  display: flex;
  flex-wrap: wrap;
  gap: 150px;
  justify-content: center;
  align-items: center;

}

#first_card {
  /* box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.24); */
  width: 18rem;
  height: 12rem;
  border: none;
  transition: all 0.5s;
}

#first_card:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

.why_us_head p {
  font-weight: 500;
}

.why_us_p p {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
}


/* Lunch Box Plans */

.lunch_box_plans {
  margin-top: 10rem;
}

.plans_card {
  /* margin-top: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan_options {
  /* border: solid 2px; */
  display: flex;
  gap: 150px;
  justify-content: center;
  align-items: center;
}

.card0-img-top {

  position: absolute;
  width: 105px;
  height: 130px;
  left: 260px;
  top: 1026px;

}

.veg-1 {
  /* border: solid 2px; */
  /* background-color: rgba(255, 255, 255, 0.5); */
  padding: 20px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:80px;
}


.veg_card {
  position: relative;
}

.veg_card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, orange, rgba(0, 0, 0, 0));
  z-index: 1;
  pointer-events: none;
}

.veg_card img {
  box-shadow: 0 20px 20px -20px black;
  position: relative;
  z-index: 2;
  /* Ensure the image is above the pseudo-element */
}


.card-body {
  position: relative;
  z-index: 2;
  /* Ensure the content is above the pseudo-element */
}

.veg_card {
  /* width: 23rem; */
}

.veg_card_title {
  margin-right: 30px;
}

.veg_card_title h5 {
  color: rgb(47, 72, 88, 1);
  font-weight: 800;
  font-size: 2rem;
}
.veg_card p ul li{
font-weight: 500;
font-size: 15px;
}
.veg_card_title span{

 margin-bottom: 14px;
}

@media screen and (max-width: 992px) {
  .right{
    display: none;
  }
  .left{
    display: flex;
    flex-direction: column;
    /* background-image: url('../Images/Vector.svg'); */
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

hr{
  margin-top: 4rem;
  box-shadow: 0 10px 10px -10px #333;
}

.know_more_btn{
  background-color: orange;
  color: white;
  margin-bottom: 1rem;
}