.login_svg{
    width: 35rem;
    /* border: solid 2px; */
}
.top_content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.top_content img{
    width: 5rem;

}

.login_container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:5rem;
}

.right_head h2{
    font-size: 3rem;
    font-weight: 700;

}
.right_head p{
    font-size: 1rem;
    font-weight: 600;
}
.right_content{
    margin: 0px 15px;
}

.login_btn button{
    background-color: #FF9F00;
    color: white;
}

#second_address:hover{
    cursor: pointer;
    color: #FF9F00;
}


@media (max-width:768px) {

    .left_content img{
        display: none;
    }

    .login_container{
        margin-top:10px;
        flex-wrap: wrap;
        align-items: center;


justify-content: center;   
 }
    
}

.banner_img{
    display: none;
}

.custom-file-upload{
    border-radius: 0.5rem;
    box-shadow: 2px 2px 0px 2px rgb(214, 211, 211);
}

.custom-file-upload:hover{
    cursor: pointer;
}